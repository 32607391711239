<!-- frontend/src/components/MainNavbar.vue -->
<template>
  <nav
      id="navbar"
      class="navbar navbar-expand-lg navbar-light fixed-top"
      v-bind:style="{
      backgroundColor: auth.account ? '#9f8855' : ''
    }"
  >
    <div class="container-fluid">
      <div class="navbar-brand d-flex align-items-center" v-if="auth.account">
        <button
            class="navbar-toggler me-2 d-md-none"
            type="button"
            @click="EventBus.emit('showMenuOffcanvas')"
            aria-label="Navigation öffnen"
        >
          <FontAwesomeIcon icon="bars" class="text-white fs-4"/>
        </button>
      </div>
      <form class="d-none d-md-flex w-25" v-if="auth.account">
        <input
            class="form-control"
            type="search"
            placeholder="Module durchsuchen"
            aria-label="Search"
            @click="EventBus.emit('showSearchModal')"
            readonly
        />
      </form>
      <div class="d-flex align-items-center" v-if="auth.account">
        <button
            class="navbar-toggler d-md-none ms-2 border-0 me-2"
            type="button"
            aria-label="Search"
            @click="EventBus.emit('showSearchModal')"
        >
          <FontAwesomeIcon icon="search" class="text-white fs-4"/>
        </button>
        <div class="d-flex align-items-center">
          <div
              class="d-none d-md-flex flex-column me-4"
              v-if="user.daysUntilExpiration !== -1"
          >
            <p class="m-0 text-white">
              Testversion: {{ user.daysUntilExpiration }} Tage verbleibend
            </p>
          </div>
          <button
              class="btn btn-outline-light rounded-circle"
              @click="EventBus.emit('showUserProfileOffcanvas')"
              style="width: 40px; height: 40px"
          >
            <FontAwesomeIcon icon="user"/>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {useAuthStore} from "@/store/auth";

import InterzeroLogo from "@/assets/interzero_logo.svg";
import {computed, onMounted, onUnmounted, ref} from "vue";
import logger from "@/utils/logging";
import EventBus from "@/eventBus";
import {useUserStore} from "@/store/user";
import emvialogo from "@/assets/emvia_living_logo.png";

const cursorActive = ref(false);
const auth = useAuthStore();
const user = useUserStore();
const logo = computed(() =>
    user.customLogo || user.isInterzero ? InterzeroLogo : emvialogo,
);
const logoClass = computed(() => {
  if (user.customLogo) {
    return "custom-logo";
  }
  return user.isInterzero ? "interzero-logo" : "emvia-logo";
});

const showEasterEgg = () => {
  if (user.isInterzero) {
    return;
  }
  logger.userInfo(
      "🥚🎉 Ein wildes Osterei erscheint! Jetzt hast du das wahre Emvia-Erlebnis beim Erforschen unserer AI-Templates! 🤖",
  );
  cursorActive.value = true;
  document.body.style.cursor = `url(${emvialogo}), auto`;
};

const searchModalOnKeyDown = (e: KeyboardEvent) => {
  if (e.ctrlKey && e.key === "k") {
    e.preventDefault();
    EventBus.emit("showSearchModal");
  }
};

onMounted(() => {
  window.addEventListener("keydown", searchModalOnKeyDown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", searchModalOnKeyDown);
  if (cursorActive.value) {
    document.body.style.cursor = "auto";
  }
});
</script>

<style>
.bg-emvia {
  background: linear-gradient(245.31deg, #00c2ff -5.97%, #7c32da 139.97%);
}

.bg-interzero {
  background: linear-gradient(245.31deg, #009dd3 -5.97%, #002652 139.97%);
}

.emvia-logo {
  max-height: 30px;
  padding-bottom: 8px;
}

.custom-logo {
  max-height: 30px;
}

.interzero-logo {
  max-height: 30px;
  padding-bottom: 4px;
}

.bg-emvia {
  background-color: #9f8855;
}

.emvia-logo {
  height: 40px;
  width: 100px;
  margin-left: 40px;
  padding-bottom: 8px;
}
</style>
